export default {
  methods: {
    getErrors(type, model, name, extra) {
      const errors = []
      if (!model.$dirty) return errors
      switch (type) {
        case 'password':
          !model.required && errors.push('Password is required')
          !model.minLength &&
            errors.push('Password must be at least 8 characters long')
          break
        case 'email':
          !model.required && errors.push('E-mail is required')
          !model.email && errors.push('Must be valid e-mail')
          break
        case 'text':
          !model.required && errors.push(`${name} is required`)
          !model.minLength &&
            model.minLength !== undefined &&
            errors.push(`${name} must be minimum length ${extra}`)
          break
        case 'amount':
          !model.required && errors.push(`${name} is required`)
          !model.numeric && errors.push(`${name} must be whole numbers`)
          !model.minValue &&
            errors.push(`Minimum fund ${name} must be 100 or above`)
          break
        case 'fqdn':
          !model.required && errors.push(`${name} is required`)
          !model.url && errors.push(`${name} is not a valid url`)
          break
        case 'port':
          !model.required && errors.push(`${name} is required`)
          !model.porter && errors.push(`${name} is not a valid port number`)
          break
        case 'mobile':
          !model.required && errors.push('Mobile number is required')
          break
        case 'endpoints':
          !model.required &&
            errors.push('You must define a minimum of 1 endpoint')
          break
        default:
      }
      return errors
    },
  },
}
