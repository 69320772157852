<template>
  <div>
    <section id="successReset">
      <v-container class="register_container">
        <v-row class="d-flex flex-column justify-center align-center">
          <h3 class="register-card__header">{{ action }} Successful</h3>
          <s-card class="register-card col-12" outlined>
            <v-row class="d-flex justify-center">
              <img :src="require(`@/assets/svgs/successPasswordReset.svg`)" />
            </v-row>
            <s-btn
              testButton="submit-button"
              type="submit"
              color="green"
              class="ma-0"
              elevation="0"
              @click="$router.push('login')"
              block
            >
              Sign In
            </s-btn>
          </s-card>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordSuccess',
  props: {
    action: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
#successReset {
  @include center-children;

  & .register_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.register-card {
  @include auth-card;
}

.register-card__header {
  @include auth-header;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
  text-decoration: underline;
}
</style>
